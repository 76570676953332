.fulfillmentRow {
    margin-top: 16px;
    display: flex;
    flex-direction: row; 

    .header {
        line-height: 1.2rem;
    }

    .icon {
        margin-right: 8px;
    }

    .directions {
        font-weight: 500;
        color: $link-blue;
    }

    .deliveryAddress {
        font-size: 14px;
    }

    .deliveryInstructions {
        font-size: 14px;
    }
}

