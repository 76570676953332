.offerStatusWrapper{
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: inherit;
  font-weight: 400;
  width: 100%;
  &:disabled {
    cursor: default
  }
  :focus {
    outline: auto;
  }
  .offerStatus {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    border: 1px 0px 0px 0px solid $gray-30;
    color: var(--tsw-primary-hover-color);
    font-size: small;
    line-height: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    background-color: var(--tsw-primary-color-25);
    &.applied {
      background-color: #E7F7E6;
      color: #408140;
    }
    .offerDesc {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      font-weight: 500;
    }
    .tagIcon {
      height: 24px;
      width: 24px;
    }
  }

}
.modal .offerItemModalWrapper {
  @media (max-width: 450px) {
    padding: 0;
  }
}

.modal .offerItemModal {
  height: 75%;
  width: 40%;
  border-radius: 8px;
  overflow-y: auto;

  @media (min-width: 450px) {
    &.fullMenuModal {
      height: fit-content;
    }
  }

  @media (max-width: 450px) {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .content {
    border-top: 1px solid $gray-30;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .menuGroupHeader {
      color: #646464;
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      padding-top: 48px;
      text-transform: uppercase;
    }

    .menuGroupList {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 18px;
      font-weight: 600;
      padding-top: 8px;
      padding-left: 0;
      border: 0.75px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      li:not(:last-child) {
        border-bottom: 0.75px solid #CECECE;
      }
      li {
        font-size: 16px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 24px;
        padding: 24px 0px;


        button {
          background: none;
          font: inherit;
          cursor: pointer;
          color: inherit;
          font-weight: 400;
          border: 1px solid  #CDCECE;
          padding: 4px 24px;
          border-radius: 8px;
          :focus {
            outline: auto;
          }
        }
      }
    }
    .fullMenuOffer {
      padding-top: 24px;
      padding-bottom: 8px;
    }
  }
}
.offerItemsGrid {
  display: grid;
  gap: 12px;
  padding-top: 36px;
  grid-template-rows: 1fr auto;
  grid-template-columns: repeat(3, 1fr);
  @include md-down {
    grid-template-columns: 1fr 1fr;
  }
}

.offerItem {
  background: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  text-align: left;
  border: 0.75px solid #e0e0e0;

  &.outOfStock {
    cursor: not-allowed;
    opacity: 66%;
  }
  &.borderStrokeNone {
    border: none;
  }
  &.borderStrokeExaggerated {
    border: 2px solid #e0e0e0;
  }

  .image {
    position: relative;
    display: flex;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    width: 100%;
    .imgWrapper {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
    .imgGradient {
      position: absolute;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      background: linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.013064600840336116) 60%, rgba(0,0,0,1) 100%);
      border-radius: inherit;
    }
  }
  .textContent {
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    height: 100%;
    justify-content: space-between;
    .titleContainer {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      .title {
        font-size: 14px;
        font-weight: 600;
        color: black;
      }
    }
    .priceContainer {
      display: flex;
      flex-direction: row;
      gap: 6px;
      .price {
        font-size: 12px;
        font-weight: 400;
        color: #7A7A7A;
        &.free {
          text-decoration: none;
        }
        text-decoration: line-through;
      }
    }
    .itemTag {
      font-size: 8px;
    }
  }

  border-radius: 8px;
  &.roundedCornerNone {
    border-radius: 1px;
  }
  &.roundedCornerExaggerated {
    border-radius: 20px;
  }

  box-shadow: 0 2px 4px 0 #e0e0e0, 0 0 6px 0 #00000005;
  &.dropShadowNone {
    box-shadow: none;
  }
  &.dropShadowExaggerated {
    box-shadow: 0 4px 8px 0 #0000000F, 0 0 4px 0 #0000000A;
  }

  &:hover {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.percentBar {
  height: 6px;
  background-color: var(--tsw-primary-color-50);
  .percentFill {
    background-color: var(--tsw-primary-color-75);
    height: 100%;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
}
